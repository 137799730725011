import React from "react";
import "../Assets/css/error404.css";
import { Link } from "gatsby";

class NotFoundPage extends React.Component {
  componentDidMount() {
    const charming = require("charming");
  }

  render() {
    return (
      <main className="error404">
        <div className="noise"></div>
        <div className="overlay"></div>
        <div className="terminal">
          <h6>
            Error <span className="errorcode">404</span>
          </h6>
          <p className="output">
            This looks like a navigation error, either the requested page does
            not exist or the terminal is faulty.
          </p>
          <p className="output">
            Please try to
            <Link className="error-link " to="/">
              go back home
            </Link>
          </p>
          <p className="output">Continue from there.</p>
        </div>
      </main>
    );
  }
}

export default NotFoundPage;
